<template>
  <v-app class="entrada-salida">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent" style="height: 56px;">
        <!-- <v-btn class="back white" icon large @click="$router.go(-1)">
          <v-icon class="icon atras"></v-icon>
        </v-btn> -->
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon caja"></v-icon>
          </div>
        </v-row>
        <div class="d-flex align-center pr-4 pl-14">
          <!-- <v-text-field class="buscar ml-7 pt-0" hide-details clearable text ref="buscador" label="Buscar / Añadir" v-model="buscador" @click:clear="clearBuscador()"></v-text-field> -->
          <v-text-field class="buscar ml-7 pt-0" hide-details clearable text ref="buscador" label="Buscar" v-model="buscador" @click:clear="clearBuscador()"></v-text-field>
          <v-btn class="icon search" icon large> </v-btn>
          <v-text-field class="buscar pt-4" style="opacity: 0;width: 0px;" id="textEan" text ref="textEan" label="Ean" v-model.lazy.trim="ean" @keydown.enter="searchByEanCode()"></v-text-field>
        </div>
      </div>
    </header>
    <Menu/>
    <v-main class="mt-14 d-flex flex-column">
      <div class="controles">
        <v-btn-toggle toggle_none class="d-flex col-12 pb-0 px-0">
          <v-btn class="flex-fill" @click="lecturaPistola()" >
            <v-icon class="mr-2">mdi-barcode-scan</v-icon>
            Pistola
          </v-btn>
          <v-btn class="flex-fill" @click="tipoLectura=1">
            <v-icon class="mr-2">mdi-camera-outline</v-icon>
            Camara
          </v-btn>
          <v-btn class="flex-fill" @click="tipoLectura=2">
            <v-icon class="mr-2">mdi-hand-wave-outline</v-icon>
            Manual
          </v-btn>
        </v-btn-toggle>
      
        <v-card v-if="tipoLectura === 1" class="col-12 pa-0 ma-0 border-rounded-0">
          <Scan @search-by-ean-code="searchByEanCode" />
        </v-card>

        <v-card v-if="tipoLectura === 2" class="d-flex py-0 px-6 ma-0">
          <v-text-field class="buscar mt-0 px-4" clearable id="textEanManual" text ref="textEanManual" label="Ean"
            v-model.lazy.trim="ean" @keydown.enter="searchByEanCode()"></v-text-field>
          <v-icon class="mr-5 primary--text">mdi-barcode</v-icon>
        </v-card>
      </div>
      <v-card-title class="d-flex d-flex align-center py-3 text-h6 font-weight-light primary--text">
        <v-list-item-avatar class="accent my-0 ">
          <v-icon dark> mdi-archive</v-icon>
        </v-list-item-avatar>
        <div>
          Salida
          <v-btn @click="expandirContraer()" icon class="mr-auto" color="primary lighten-1">
            <v-icon v-if="expandir">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </v-card-title>
       
        <div v-if="cargando" class="mt-15 text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-card v-else class="scroll-order" :key="keyRefresh">
          <v-expansion-panels multiple v-model="openedPanel">
            <v-expansion-panel v-for="(item, i) in lineas" :key="i" ref="articulos" :id="i">
              <v-expansion-panel-header class="px-2">
                <v-img :src="Object.keys(fotos).length > 0 ? fotos[i] !== undefined ? fotos[i].url  : '' : ''" class="pr-4" max-height="80" max-width="90" contain> </v-img>
                  <v-card-title class="product d-flex pa-0 flex-column justify-start align-start">
                    <p class="text-subtitle-1 mb-0 font-weight-bold primary--text pa-0 text-uppercase">{{ item[0].modelo }}
                    </p>
                    <p class=" mb-0 accent--text pa-0 subtitle-1 d-flex">{{ item[0].localizador }}</p>
                    <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ item[0].color }}</p>
                  </v-card-title>
                  <v-btn @click="eliminarArticulo(i)" icon class="ml-auto" color="primary lighten-1" v-if="item[0].added">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row class="d-flex justify-start ma-0 stock-table">
                  <v-col class="d-flex pa-0 flex-column align-center jusitify-start flex-grow-0" v-for="(talla, y) in item" :key="y + 'a'">
                    <p class="px-4 py-1 text-body-2 font-weight-medium primary--text mb-0 text-center" v-text="talla.talla"></p>
                    <!-- <p class="pa-1 mb-0 text-center grey--text text--darken-2" v-once v-text="talla.unid"></p> -->
                    <v-text-field class="pt-0 text-h6 text-center" 
                      :ref="talla.eans[0]" 
                      label="Stock" 
                      :value="talla.unid"
                      v-model.number="talla.unid"
                      @focus="$event.target.select()" 
                      type="number">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      <div class="hueco py-8 mt-auto"></div>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center py-5 px-3 inventory-sheet">
          <v-btn @click="enviarSalida()" right fixed fab bottom class="secondary" elevation="0">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <h2 class="button text-uppercase text-left primary--text ml-2 font-weight-bold pb-3 mt-2">Selecciona el destino</h2>
          <v-autocomplete
            class="justify-center mx-4 mt-0 pb-15"
            label="Tienda"
            :items="shops"
            color="accent"
            prepend-inner-icon="mdi-map-marker"
            :item-text="isSys4() ? 'clicode' : 'nombre'"
            :item-value="(item) => item"
            return-object
            v-model="tiendaDestino"
          ></v-autocomplete>
        </v-sheet>
      </v-bottom-sheet>
    </v-main>
    <v-btn class="accent rounded-0" text fixed bottom left @click="borrarSalida()"
      v-if="Object.keys(lineas).length > 0">
      <v-icon class="mr-2" color="white">mdi-cancel</v-icon> Reiniciar
    </v-btn>
    <v-btn class="secondary rounded-0 elevation-0" fixed absolute bottom right @click="sheet=true"
      v-if="Object.keys(lineas).length > 0">
      <v-icon class="mr-1 " color="white">mdi-send-variant-outline</v-icon>Enviar
    </v-btn>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../../components/Menu.vue';
import Scan from '../../components/Shop/scan.vue';
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

import { AlbaranApiService } from '../../api/AlbaranApiService';
const albaranApiService = new AlbaranApiService();
import { TiendaApiService } from '../../api/TiendaApiService';
const tiendaApiService = new TiendaApiService();

export default Vue.extend({
  name: 'Salidas',
  components: {
    Menu,
    Scan,
  },
  props: {
  },
  data: () => ({
    ean: '',
    audioError: new Audio( require('@/assets/sonidos/error.mp3') ),
    audioOk: new Audio( require('@/assets/sonidos/ok.mp3') ),
    cargando: false,
    opUid: '',
    opId: '',
    clicode:'',
    lineas: {},
    albaran: {},
    fotos: {},
    buscador: '',
    openedPanel: [],
    isAlbaran: null,
    keyRefresh: 0,
    sheet: false,
    tiendaDestino: null,
    tipoLectura: 0, //0->pistola 1->camara 2-> manual    
    expandir: false
  }),
  computed: {
    cambiotienda() {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid() {
      return this.$store.getters.getOpuid;
    },
    cambiotiendaCliCode() {
      return this.$store.state.userInfo.clicode;
    },
    shops () {
      return this.$store.state.userInfo.listaTiendas
    },
  },
  watch: {
    cambiotiendaUid(value) {
      this.opUid = value;
    },
    cambiotienda(value) {
      this.opId = value;
    },
    cambiotiendaCliCode(value) {
      this.clicode = value;
    },
    buscador(value) {
      this.filtrarArticulos(value)
    }
  },
  created() {
    this.$root.$emit('verificarCertificado', location.href)
    this.init()  
  },
  mounted() {
    this.ean = ''
    document.getElementById("textEan").focus()
  },
  methods: {
    init() {
      this.opId = this.$store.getters.getIdTienda
      this.opUid = this.$store.getters.getOpuid
      this.clicode = this.$store.state.userInfo.clicode      
      this.ean = ''
      // this.$refs.textEan.focus()
    },
    expandirContraer() {
      if (!this.expandir) {
        let contador = []
        for (let i = 0; i <  Object.keys(this.lineas).length; i++) {
          contador.push(i)
        }
        this.openedPanel = contador
      } else {
        this.openedPanel = []
      }
      this.expandir = !this.expandir
    },
    lecturaPistola() {
      this.tipoLectura = 0
      this.ean = ''
      document.getElementById("textEan").focus()
    },
    searchByEanCode() {
      this.ean = this.ean.trim()
      if (this.ean.length > 0) {
        if  (this.buscarEanLineas(this.ean)) {
          this.ean = ''
          this.audioOk.pause();
          this.audioOk.currentTime = 0;
          this.audioOk.play()
        } else {
          //si no esta en las lineas y tampoco al buscarlo damos error
          this.cargando = true
          tiendaApiService.getArticuloByEan(this.ean)
            .then(data =>{
              if (data) {
                console.log(data)
                let tallas = []
                let idArtic = data.id 
                data.tallas.forEach(element => {
                  if (element.eans && element.eans.length >0) {
                    let unidades = 0
                    let eanAux = []
                    if (element.eans.some(item => item.ean.toString().trim() == this.ean.toString().trim()) ) {
                      unidades = 1
                      eanAux = [this.ean.toString().trim()]
                    } else {
                      eanAux = element.eans.map(o => o.ean)
                    }
                    let precio = 0
                    // TODO: optimizar la llamada para solo coger el precio de la op y no cargar todo el stock
                    // INFO: No hace falta añadir precio
                    // let objStock = element.stocks.filter(item => item.op.msopid.toString().trim() == this.opId.toString().trim())
                    // if (objStock.length > 0) {
                    //   precio = objStock[0].precio.precio
                    // }
                    console.log(precio)
                    tallas.push({
                      art_id: idArtic,
                      color: data.color,
                      descr: data.descripcionTpv,
                      eans: eanAux,
                      localizador: data.localizador,
                      modelo: data.modelo,
                      talla: element.talla,
                      talla_interna: element.tallaInterna,
                      unid: unidades,
                      pvp: precio
                    })
                  }
                });
                this.fotos = Object.assign({[idArtic] : data.fotos[0]}, this.fotos )
                this.lineas = Object.assign({[idArtic] : tallas}, this.lineas)
                this.cargando = false
                this.$forceUpdate()
                this.keyRefresh +=1
                this.openedPanel = [Object.keys(this.lineas).findIndex( (element) => element === idArtic.toString())]
                this.$nextTick(() => {
                  document.getElementById(idArtic).scrollIntoView()
                })
                this.ean = ''
                this.audioOk.pause();
                this.audioOk.currentTime = 0;
                this.audioOk.play()
                console.log(data)
              } else {
                this.cargando = false
                this.audioError.pause();      
                this.audioError.currentTime = 0
                this.audioError.play()
                this.ean = ''
                this.$root.$emit('mostrarAlerta', 'Ean no encotrado', 'error')
              }
            })
            .catch(error => {
              this.cargando = false
              this.audioError.pause();
              this.audioError.currentTime = 0;
              this.audioError.play()
              this.ean = ''
              console.log(error)
              this.$root.$emit('mostrarAlerta', 'Error al buscar el articulo', 'error')
            })
        }
       
      } else {
        this.audioError.pause();
        this.audioError.currentTime = 0;
        this.audioError.play()
        this.ean = ''
        this.$root.$emit('mostrarAlerta', 'Ean vacio', 'error')
      }
    },
    buscarEanLineas(ean) {
      let contador = 0
      for (var artic in this.lineas) {
        for (let x = 0; x < this.lineas[artic].length; x++) {
          for (let y = 0; y < this.lineas[artic][x].eans.length; y++) {
            if (this.lineas[artic][x].eans[y] === ean) {
              document.getElementById(artic).scrollIntoView();
              this.lineas[artic][x].eans = [ean]
              this.openedPanel = [contador]
              this.lineas[artic][x].unid++
              return true
            } 
          }
        }
        contador++
      }
      return false
    },
    borrarSalida(){
      this.$root.$confirm.open('Descartar', '¿Deseas descartar las lecturas?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.lineas = {}
        }
      })
    },
    eliminarArticulo(idArtic) {
      console.log(idArtic)
      console.log(this.lineas)
      this.$root.$confirm.open('Eliminar', '¿Deseas eliminar el articulo?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          delete this.lineas[idArtic]
          this.$forceUpdate()
        }
      })
      
    },
    filtrarArticulos() {
      if (this.buscador && this.buscador.length > 0) {
        let lineasFiltradas = []
        console.log(Object.values(this.lineas))
        lineasFiltradas = Object.values(this.lineas).filter(item =>{
          return (item[0].localizador.toString().indexOf(this.buscador) === 0 || 
                  item[0].modelo.toUpperCase().indexOf(this.buscador.toUpperCase()) === 0)
        })
        console.log(lineasFiltradas)
        this.$refs.articulos.forEach(element => {
          if (lineasFiltradas.some(item => item[0].art_id == element.$el.id )) {
            element.$el.hidden = false
          } else {
            element.$el.hidden = true
          }
        });
      } else {
        this.$refs.articulos.forEach(element => {
            element.$el.hidden = false
        });
      }
    },
    enviarSalida() {
      if (this.tiendaDestino) {
        this.$root.$confirm.open('Eliminar', '¿Deseas registrar la salida?', { color: 'primary' }).then((confirm) => {
          if (confirm) {
            this.cargando = true
            let salida = []
            salida.push(this.opUid)
            salida.push(this.opId)
            salida.push(this.tiendaDestino.id)
            salida.push(this.clicode)
            salida.push(this.tiendaDestino.clicode)
            salida.push(this.$store.state.userInfo.codVendedor)
            for (var artic in this.lineas) {
              for (let x = 0; x < this.lineas[artic].length; x++) {
                if (this.lineas[artic][x].unid > 0) {
                  salida.push({
                    tallaInterna: this.lineas[artic][x].talla_interna + 1, //esta en base 1
                    tallaExterna: this.lineas[artic][x].talla,
                    unidades: this.lineas[artic][x].unid,
                    ean: this.lineas[artic][x].eans[0], //TODO: ean leido
                    idArtic: artic,
                    precio: this.lineas[artic][x].pvp
                    })
                }
              }
            }
            this.tiendaDestino = null
            console.log(salida)
            albaranApiService.enviarSalida(salida)
              .then(() => {
                this.cargando = false
                this.$root.$emit('mostrarAlerta', 'Salida registrada con éxito', 'green')
                this.lineas = {}
                this.sheet = false
              })
              .catch(error => {
                this.cargando = false
                this.$root.$emit('mostrarAlerta', 'Error al enviar la Salida', 'error')
                console.log('alabaranApiService - enviarSalida ' + error)
              })
          }
        })
      } else {
        this.$root.$emit('mostrarAlerta', 'Debe seleccionar la tienda de destino', 'error')
      }
    },
    // downloadPdf() {
    //   this.$nextTick().then(() => {
    //     const contentHtml = this.$refs['toPdf'].$el;
    //     html2canvas(contentHtml).then(function(canvas) {
    //       const doc = new jsPDF();
    //       doc.addImage(canvas.toDataURL(), 'PNG', 10, 10, 500 ,500);
    //       doc.save('albaran.pdf');

    //     });
    //   })
    // },
  },
});
</script>
