<template>
  <v-app>
    <header class="pl-16 v-app-bar" style="background: #fff;">
      <div class="v-toolbar__content" style="height: 56px;">
       
          <v-btn class="back white" icon large>
            <router-link :to="{ name: 'Entradas'}">
              <v-icon class="icon atras"></v-icon>
            </router-link>
          </v-btn>
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon caja"></v-icon>
          </div>
        </v-row>
          <div class="d-flex align-center mr-auto pr-4 pl-14">
            <v-text-field
                label="Código SSCC"
                text
                clearable
                hide-details
                ref="codigoSSCC"
                v-model="sscc" 
                class="ml-7 pt-0"
                @keyup.enter="searchByEanCode(codigoSSCC)"
              ></v-text-field>
              <v-icon class="icon search" icon large> </v-icon>
            </div>
          </div>
    </header>
    <Menu />
     
    <v-main class="order margin-top">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <!-- <v-row class="col-12 pa-0 ma-0 scan">
          <v-col class="primary col-12 pa-0">
            <Scan @search-by-ean-code="searchByEanCode"/>
          </v-col>
        </v-row> -->
        <v-list subheader  class="mt-3">
          <div v-for="(bultos, i) in bultosAlbaran" :key="i">
            <v-divider></v-divider>
            <v-subheader class="text-uppercase">
              <v-icon class="mr-2 text-body-1">mdi-file-document-outline</v-icon>{{ bultos[0].albaran_codigo }} {{ bultos[0].albaran_fecha }} <span class="font-weight-black " v-text=" bultos[0].origen_cod + '-' +  bultos[0].origen_nombre"></span></v-subheader> 
            <v-list-item v-for="bulto in bultos" :key="bulto.barcode" @click="verDetalle(bulto)">
              <v-list-item-avatar>
                <v-icon class="accent" dark>
                  mdi-archive-outline
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="primary--text"><span>SSCC </span> <span class="font-weight-bold" v-text="bulto.barcode"></span> </v-list-item-title>
                <!-- <v-list-item-subtitle class="accent--text" v-text="bulto.albaran_fecha"></v-list-item-subtitle> -->
                <v-list-item-subtitle  class="accent--text font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">{{bulto.origen_cod}} {{bulto.origen_nombre}}</v-list-item-subtitle>
                <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ bulto.albaran_fecha }}</p>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <!-- <v-icon :color="bulto.color" v-text="bulto.icon"></v-icon> -->
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
          <v-divider></v-divider>
        </v-list>
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Menu from '../../components/Menu.vue';
// import Scan from '../../components/Shop/scan.vue';

import { AlbaranApiService } from '../../api/AlbaranApiService';
const albaranApiService = new AlbaranApiService();

export default {
  name: 'odt',
  components: {
    Menu,
    // Scan
  },
  data() {
    return {
      codOdt: '',
      opUid: this.$store.getters.getOpuid,
      cargando: false,
      bultosAlbaran: [
        { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC1', number: '0028434690000', bold: '255732' },
        { icon: 'mdi-close-circle', color: 'secondary', subtitle: '3/03/2014', title: 'SSCC2', number: '0028434690000', bold: '255732' },
      ],
      
      sscc: ''
    };
  },
  computed: {
    cambiotienda() {
      return this.$store.getters.getOpuid;
    },
  },
  watch: {
    cambiotienda(value) {
      this.opUid = value;
      this.init();
    },
  },
  created() {
    this.$root.$emit('verificarCertificado', location.href)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.codigoSSCC = ''
      this.getBultos()
    },
    getBultos() {
      this.cargando = true
      albaranApiService.getBultos(this.opUid)
        .then((data) => {
          this.cargando = false
          let result = Object.groupBy(data.data, ({ albaran_id }) => albaran_id)
          this.bultosAlbaran = result
          console.log(data)
        })
        .catch((error) => {
          this.cargando = false
          this.$root.$emit('mostrarAlerta', 'Error al obtener bultos', 'error')
          console.log('albaranApiService - getBultos ' + error)
        });
    },
    searchByEanCode() {
      this.sscc = this.sscc.trim()
      if (this.sscc.length > 0) {
        let bulto = this.bultos.find(element => element.barcode === this.sscc)
        if (bulto !== undefined) {
          this.verDetalle(bulto)
        }
      }
    },
    verDetalle(bulto) {
      // if (this.$store.getters.getAlabaranEnCurso && this.$store.getters.getAlabaranEnCurso !== null) {
      //   if (this.$store.getters.getAlabaranEnCurso.albaran_uid !== bulto.albaran_uid) {
      //     this.$root.$confirm.open('Conexión', 'Ya hay un albaran en curso, se borraran las lecturas ¿Desea continuar?', { color: 'primary' }).then((confirm) => {
      //       if (confirm) {
      //         this.$store.commit('setBultoAlbaranEnCurso', bulto)
      //         this.$router.push({ path: '/AlbaranDetalle/' + bulto.albaran_uid });
      //       }
      //     })
      //   } else {
      //     this.$store.commit('setBultoAlbaranEnCurso', bulto)
      //     this.$router.push({ path: '/AlbaranDetalle/' + bulto.albaran_uid });
      //   }
      // } else {
        this.$store.commit('setBultoAlbaranEnCurso', bulto)
        this.$router.push({ path: '/AlbaranDetalle/' + bulto.barcode_uid });
      // }
    }
  },
};
</script>
