<template>
  <v-app class="">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
        </v-row>
        <!-- <div class="d-flex align-center mr-auto pr-4 pl-14">
            <v-text-field
                label="Buscar albaran"
                text
                clearable
                hide-details
                r ef="codigoSSCC"
                v-model="sscc" 
                class="pt-0"
                @keyup.enter="searchByEanCode(codigoSSCC)"
              ></v-text-field>
              <v-icon class="icon search" icon large> </v-icon>
          </div> -->
      </div>
    </header>
    <Menu />

    <v-main class="inventory2 margin-top">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <v-list two-line>
          <div v-for="inventario in inventariosOp" :key="inventario.nombre">
            <v-list-item class="finalizado" :to="{ name: 'InventarioGestion', params: { inventario: inventario } }">
              <v-list-item-avatar>
                <v-icon class="accent" dark>
                  mdi-counter
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="primary--text d-flex">
                  {{ inventario.clicode }}
                  <span class="font-weight-bold ml-1">
                    {{ inventario.nombre }}
                  </span>
                  <v-chip v-if="inventario.estadoInventario === 1" x-small class="white--text ml-2 blue-grey lighten-1 ml-auto">
                    Pendiente
                  </v-chip>
                  <v-chip v-if="inventario.estadoInventario === 2" x-small class="white--text ml-2 orange ml-auto">
                    En proceso
                  </v-chip>
                  <v-chip v-if="inventario.estadoInventario === 3" x-small class="white--text ml-2 teal ml-auto">
                    Terminada
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
                  <v-icon class=" text-subtitle-1 mr-1">mdi-play-circle-outline</v-icon>
                  {{ (inventario.fechaInicio) ? new Date(inventario.fechaInicio).toLocaleDateString('es-ES', {
                    year:
                      'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
                  }) : '' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
                  <v-icon class="text-subtitle-1 mr-1">mdi-stop-circle-outline</v-icon>
                  {{ (inventario.fechaFin) ? new Date(inventario.fechaFin).toLocaleDateString('es-ES', {
                    year:
                      'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
                  }) : '' }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle class="accent--text" v-text="inventario.fechaFin"></v-list-item-subtitle> -->
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-btn icon>
                  <v-icon :color="bulto.color" v-text="bulto.icon"></v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </div>

    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
// import { TiendaApiService } from '../api/TiendaApiService';
import { InventarioApiService } from '../api/InventarioApiService';
// const tiendaApiService = new TiendaApiService();
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'InventariosControl',
  components: {
    Menu,
  },
  data() {
    return {
      cargando: false,
      inventariosOp: []
    };
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$root.$emit('verificarCertificado', location.href)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.inventariosOp = []
      this.getInventariosTiendas()
    },

    getInventariosTiendas() {
      this.cargando = true
      let opUids = this.$store.state.userInfo.listaTiendas.map(a => a.opuid).join(',')
      inventarioApiService.getInventarios(opUids)
        .then(data => {
          this.cargando = false
          console.log(data, 11)
          this.$store.state.userInfo.listaTiendas.forEach(tienda => {
            let inventario = []
            if (data) {
              inventario = data.filter(item => item.msicOp === tienda.opuid)
            }
            if (inventario.length > 0) {
              let estadoInventario = 0
              if (inventario[0].msicFechaInicio === '' || inventario[0].msicFechaInicio === null || inventario[0].msicFechaInicio === undefined) {
                estadoInventario = 1 //sin inciar
              } else if ((inventario[0].msicFechaInicio !== '' && inventario[0].msicFechaInicio !== null && inventario[0].msicFechaInicio !== undefined) &&
                (inventario[0].msicFechaFin === '' || inventario[0].msicFechaFin === null || inventario[0].msicFechaFin === undefined)) {
                estadoInventario = 2 // iniciado sin finalizar -> pendiente
              } else if (inventario[0].msicFechaInicio !== '' && inventario[0].msicFechaInicio !== null && inventario[0].msicFechaInicio !== undefined &&
                inventario[0].msicFechaFin !== '' && inventario[0].msicFechaFin !== null && inventario[0].msicFechaFin !== undefined) {
                estadoInventario = 3
              } else {
                estadoInventario = 0
              }
              this.inventariosOp.push({
                clicode: tienda.clicode,
                nombre: tienda.nombre,
                fechaInicio: inventario[0].msicFechaInicio,
                fechaFin: inventario[0].msicFechaFin,
                notas: inventario[0].msicNotas,
                opuid: inventario[0].msicOp,
                estadoInventario: estadoInventario
              })
            } else {
              this.inventariosOp.push({
                clicode: tienda.clicode,
                nombre: tienda.nombre,
                fechaInicio: '',
                fechaFin: '',
                notas: '',
                opuid: tienda.opuid,
              })
            }
          });
        })
        .catch(error => {
          this.cargando = false
          this.$root.$emit('mostrarAlerta', 'Error al obtener inventarios', 'error')
          console.log(error)
        })
    }
  },
});
</script>
