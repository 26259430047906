<template>
  <v-app class="inventario">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-btn class="back white" icon large>
          <router-link :to="{ name: 'InventariosControl' }">
            <v-icon class="icon atras"></v-icon>
          </router-link>
        </v-btn>
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
        </v-row>
      </div>
    </header>
    <Menu />
    <v-main class="pt-2 margin-top pt-3 d-flex flex-column">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </div>
      <v-list v-else>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="accent" dark>
              mdi-counter
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content title="inventario.nombre"
            :subtitle="inventario.fechaInicio + ' - ' + inventario.fechaFin" :text="inventario.notas">
            <v-list-item-title class="d-flex primary--text">
              {{ inventario.clicode }}
              <span class="font-weight-bold ml-1">
                {{ inventario.nombre }}
              </span>
              <v-chip v-if="estadoInventario === 1" x-small class="white--text ml-2 blue-grey lighten-1 ml-auto">
                Pendiente
              </v-chip>
              <v-chip v-if="estadoInventario === 2" x-small class="white--text ml-2 orange ml-auto">
                En proceso
              </v-chip>
              <v-chip v-if="estadoInventario === 3" x-small class="white--text ml-2 teal ml-auto">
                Terminada
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
              <v-icon class="text-subtitle-1 mr-1">mdi-play-circle-outline</v-icon>
              {{ (inventario.fechaInicio) ? new Date(inventario.fechaInicio).toLocaleDateString('es-ES', {
                year:
                  'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : '' }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class=" font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
              <v-icon class="  text-subtitle-1 mr-1">mdi-stop-circle-outline</v-icon>
              {{ (inventario.fechaFin) ? new Date(inventario.fechaFin).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="cargandoLectores" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </div>
      <v-list v-else class="py-0 d-flex" v-for="(lector, index) in lectores" :key="index">
        <v-list-item>
          <v-list-item-avatar class="my-0 mr-1">
            <v-icon class="accent--text">mdi-arrow-right-bottom</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title class="d-flex align-center primary--text font-weight-bold">
              <v-icon class="primary--text mr-1">
                mdi-account-outline
              </v-icon>{{ lector[0] }}
            </v-list-item-title>
            <v-list-item-subtitle class="accent--text font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
              <v-chip v-if="lector[1] && !lector[2]" x-small class="white--text ml-2 orange">En proceso</v-chip>   
                <!-- <v-icon class="text-subtitle-1 mr-1">mdi-play-circle-outline</v-icon> {{ (lector[1]) ? "ULTIMA LECTURA (" +  ( new Date(lector[1]).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })) + ')' : ''}} -->
            </v-list-item-subtitle>
            <v-list-item-subtitle class="accent--text font-weight-medium mb-0 pa-0 d-flex text-truncate text-uppercase">
              <v-chip v-if="lector[2]" x-small class="white--text ml-2 teal">Completado</v-chip>   
                <!-- <v-icon class="text-subtitle-1 mr-1">mdi-stop-circle-outline</v-icon> {{ (lector[2]) ? "Completado (" +  ( new Date(lector[2]).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })) + ')' : ''}} -->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-main>
    <div class="hueco py-9 mt-auto"></div>
    <v-btn bottom right fixed class="secondary elevation-0 rounded-0" v-if="estadoInventario === 3"
      @click="enviarInventario()">
      <v-icon class="mr-1 ">mdi-send-variant-outline</v-icon>
      Enviar
    </v-btn>
    <v-btn bottom left fixed class="accent elevation-0 rounded-0" v-if="estadoInventario === 3"
      @click="reabrirInventario()">
      <v-icon>mdi-autorenew </v-icon>
      Reabrir
    </v-btn>
    <v-btn bottom right fixed class="secondary elevation-0 rounded-0" v-if="estadoInventario === 1"
      @click="iniciarInventario()">
      <v-icon>mdi-play-outline</v-icon>
      Iniciar
    </v-btn>
    <v-btn bottom right fixed class="secondary elevation-0 rounded-0" v-if="estadoInventario === 2"
      @click="finalizarInventario()">
      <v-icon class="mr-1">mdi-check</v-icon>
      Finalizar
    </v-btn>
    <v-btn bottom left fixed class="accent elevation-0 rounded-0" v-if="estadoInventario === 2"
      @click="reiniciarInventario()">
      <v-icon class="mr-1">mdi-cancel</v-icon>
      Reiniciar
    </v-btn>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import { InventarioApiService } from '../api/InventarioApiService';
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'InventarioGestion',
  components: {
    Menu,
  },
  props: {
    inventario: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      cargando: false,
      cargandoLectores: false,
      estadoInventario: 0, // diferentes estados en los que se encuentra el inventario 
      usuario: this.$store.state.userInfo.user.substring(0, 50).toLowerCase(),
      lectores: []
    };
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$root.$emit('verificarCertificado', location.href)
    if (this.inventario === null) {
      this.$router.push({ name: 'InventariosControl' })
    }
    this.cargandoLectores = true
    this.inervalLecturas = setInterval(() => this.getLectoresEstado(), 3000)
  },
  destroyed() {
    clearInterval(this.inervalLecturas)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setEstadoInventario()
    },
    setEstadoInventario() {
      if (this.inventario.fechaInicio === '' || this.inventario.fechaInicio === null || this.inventario.fechaInicio === undefined) {
        this.estadoInventario = 1 //sin inciar
      } else if ((this.inventario.fechaInicio !== '' && this.inventario.fechaInicio !== null && this.inventario.fechaInicio !== undefined) &&
        (this.inventario.fechaFin === '' || this.inventario.fechaFin === null || this.inventario.fechaFin === undefined)) {
        this.estadoInventario = 2 // iniciado sin finalizar -> pendiente
      } else if (this.inventario.fechaInicio !== '' && this.inventario.fechaInicio !== null && this.inventario.fechaInicio !== undefined &&
        this.inventario.fechaFin !== '' && this.inventario.fechaFin !== null && this.inventario.fechaFin !== undefined) {
        this.estadoInventario = 3
      } else {
        // error estado no contemplado
        this.$root.$emit('mostrarAlerta', 'Error estado inventario desconocido -> contactar con soporte', 'error')
        console.log('inventario', this.inventario)
      }
    },
    iniciarInventario() {
      let inventarioAux = {
        msicOp: this.inventario.opuid,
        msicUsuario: this.usuario
      }
      inventarioApiService.iniciarInventario(inventarioAux)
        .then(data => {
          console.log(data)
          this.estadoInventario = 2
          this.inventario.fechaInicio = data.msicFechaInicio
        })
        .catch(error => {
          this.$root.$emit('mostrarAlerta', 'Error al iniciar el inventario', 'error')
          console.log(error)
        })
    },
    finalizarInventario() {
      this.$root.$confirm.open('Finalizar', '¿Deseas finalizar el invetario?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.finalizarInventario(inventarioAux)
            .then(data => {
              console.log(data)
              this.estadoInventario = 3
              this.inventario.fechaInicio = data.msicFechaInicio
              this.inventario.fechaFin = data.msicFechaFin
            })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al finalizar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    enviarInventario() {
      this.$root.$confirm.open('Enviar', '¿Deseas enviar el inventario a central? Esta operación no se puede reiniciar', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.cargando = true
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.enviarInventarioCompleto(inventarioAux)
            .then(data => {
              this.cargando = false
              console.log(data)
              this.$root.$emit('mostrarAlerta', 'El inventario se ha enviado con éxito', 'green')
              this.$router.push({ name: 'InventariosControl' });
            })
            .catch(error => {
              this.cargando = false
              this.$root.$emit('mostrarAlerta', 'Error al enviar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    reiniciarInventario() {
      this.$root.$confirm.open('Reiniciar', '¿Deseas reiniciar el inventario? Todas las lecturas se borraran', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.reiniciarInventario(inventarioAux)
            .then(data => {
              console.log(data)
              this.estadoInventario = 1
              this.inventario.fechaInicio = ''
            })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al reiniciar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    reabrirInventario() {
      this.$root.$confirm.open('Reabrir', '¿Deseas reabrir el inventario?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.reabrirInventario(inventarioAux)
            .then(data => {
              console.log(data)
              this.estadoInventario = 2
              this.inventario.fechaFin = ''
            })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al reabrir el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    getLectoresEstado() {
      inventarioApiService.getLectoresEstado(this.inventario.opuid)
        .then(data => {
          this.cargandoLectores = false
          this.lectores = data
        })
        .catch(error => {
          this.cargandoLectores = false
          this.lectores = []
          this.$root.$emit('mostrarAlerta', 'Error al estado lecturas', 'error')
          console.log(error)
        })
    }
  },
});
</script>
