<template>
  <v-app class="entrada-salida">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent" style="height: 56px;">
        <v-btn class="back white" icon large @click="$router.go(-1)">
          <v-icon class="icon atras"></v-icon>
        </v-btn>
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon caja"></v-icon>
          </div>
        </v-row>
        <div class="d-flex align-center pr-4 pl-14">
          <!-- <v-text-field class="buscar ml-7 pt-0" hide-details clearable text ref="buscador" label="Buscar / Añadir" v-model="buscador" @click:clear="clearBuscador()"></v-text-field> -->
          <v-text-field class="buscar ml-7 pt-0" hide-details clearable text ref="buscador" label="Buscar"
            v-model="buscador" @click:clear="clearBuscador()"></v-text-field>
          <v-icon class="icon search" icon large> </v-icon>
          <v-text-field class="buscar pt-4" style="opacity: 0; width: 0px;" id="textEan" text ref="textEan" label="Ean"
            v-model.lazy.trim="ean" @keydown.enter="searchByEanCode()"></v-text-field>
        </div>
      </div>
    </header>
    <Menu />
    <v-main class="mt-14 d-flex flex-column">
      <div class="controles">
        <v-btn-toggle toggle_none class="d-flex col-12 pb-0 px-0">
          <v-btn class="flex-fill " @click="lecturaPistola()">
            <v-icon class="mr-2">mdi-barcode-scan</v-icon>
            Pistola
          </v-btn>
          <v-btn class="flex-fill" @click="tipoLectura = 1">
            <v-icon class="mr-2">mdi-camera-outline</v-icon>
            Camara
          </v-btn>
          <v-btn class="flex-fill" @click="tipoLectura = 2">
            <v-icon class="mr-2">mdi-hand-wave-outline</v-icon>
            Manual
          </v-btn>
        </v-btn-toggle>
        <v-card v-if="tipoLectura === 1" class="col-12 pa-0 ma-0 border-rounded-0">
          <Scan @search-by-ean-code="searchByEanCode" />
        </v-card>
        <v-card v-if="tipoLectura === 2" class="d-flex py-0 px-6 ma-0">
          <v-text-field class="buscar mt-0 px-4" clearable id="textEanManual" text ref="textEanManual" label="Ean"
            v-model.lazy.trim="ean" @keydown.enter="searchByEanCode()"></v-text-field>
          <v-icon class="mr-5 primary--text">mdi-barcode</v-icon>
        </v-card>
      </div>
      <v-card v-if="isAlbaran" class="d-flex d-flex align-center py-3 text-h6 font-weight-regular">
        <v-list-item-avatar class="accent my-0 ml-4">
          <v-icon dark> mdi-file-document-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class=" primary--text">
            <span class="" v-text="albaran.albaran_codigo"></span>
            <span class="font-weight-bold ml-1" v-text="albaran.origen_cod + '-' + albaran.origen_nombre"></span>
          </v-list-item-title>
          <v-list-item-subtitle class="accent--text"><span class="font-weight-black "
              v-text="albaran.albaran_referencia"></span></v-list-item-subtitle>
          <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ albaran.albaran_fecha }}</p>
        </v-list-item-content>
        <v-btn @click="expandirContraer()" icon class="mr-auto" color="primary lighten-1">
          <v-icon v-if="expandir">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card>
      <!-- <v-list-item-content v-if="isAlbaran">
          <v-list-item-title class="primary--text font-weight-regular  pl-1">Albaran{{albaran.albaran_codigo}} <span class="font-weight-black">{{albaran.albaran_referencia}}</span></v-list-item-title>
          <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ albaran.albaran_fecha }}</p>
        </v-list-item-content>-->
      <v-card class="d-flex d-flex align-center py-3 text-h6 font-weight-regular" v-else>
        <v-list-item-avatar class="accent my-0 ml-4">
          <v-icon dark>mdi-archive-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text">SSCC<span class="font-weight-black  pl-1">
              {{ albaran.barcode }}</span></v-list-item-title>
          <v-list-item-subtitle
            class="accent--text font-weight-medium mb-0 pa-0 d-flex text-truncate">{{ albaran.origen_cod + '-' + albaran.origen_nombre }}<!--{{albaran.barcode}}{{ albaran.barcode }}--></v-list-item-subtitle>
          <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ albaran.albaran_fecha }}</p>
        </v-list-item-content>
        <v-btn @click="expandirContraer()" icon class="mr-auto" color="primary lighten-1">
          <v-icon v-if="expandir">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card>

      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-card v-else class="scroll-order" :key="keyRefresh">
        <v-expansion-panels accordion multiple v-model="openedPanel" class="mt-0">
          <v-expansion-panel v-for="(item, i) in lineas" :key="i" ref="articulos" :id="i">
            <v-expansion-panel-header class="px-4 py-2">
              <v-img :src="Object.keys(fotos).length > 0 ? fotos[i] !== undefined ? fotos[i].url : '' : ''"
                class="pr-4" max-height="80" max-width="90" contain> </v-img>
              <v-list-content class="d-flex pa-0 flex-column justify-start align-start">
                <v-list-item-title class="align-self-start my-1 font-weight-bold primary--text pa-0 text-uppercase">{{
                  item[0].modelo }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium mb-3 accent--text pa-0  d-flex">{{ item[0].localizador
                  }}</v-list-item-subtitle>
                <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ item[0].color }}</p>
              </v-list-content>
              <v-btn @click="eliminarArticulo(i)" icon class="ml-auto" color="primary lighten-1" v-if="item[0].added">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
              <!-- Este cuando todos están escaneados
              <v-icon color="success">mdi-check-circle</v-icon>
              -->
              <!-- Este cuando todos están escaneados
              <v-icon color="secondary">mdi-close-circle</v-icon>
              -->
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-row class="d-flex justify-start ma-0 stock-table">
                <v-col class="d-flex pa-0 flex-column align-center jusitify-start flex-grow-0"
                  v-for="(talla, y) in item" :key="y + 'a'">
                  <p class="text-no-wrap px-2 py-1 text-body-2 font-weight-medium primary--text mb-0 text-center"
                    v-text="talla.talla"></p>
                  <p class="pl-2 pr-7 text-no-wrap mb-0 text-center grey--text text--darken-2" v-once
                    v-text="(talla.unidInicial != undefined) ? talla.unidInicial : talla.unid"></p>
                  <v-text-field class="pt-0 text-h6 text-center" :ref="talla.eans[0]" label="Stock" :value="talla.unid"
                    v-model.number="talla.unid" @focus="$event.target.select()" type="number">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <div class="hueco py-8 mt-auto"></div>
    </v-main>
    <v-btn class="accent rounded-0" text fixed bottom left @click="borrarEntrada()"
      v-if="Object.keys(lineas).length > 0">
      <v-icon class="mr-2" color="white">mdi-cancel</v-icon> Reiniciar
    </v-btn>
    <v-btn class="secondary rounded-0 elevation-0" fixed absolute bottom right @click="enviarEntrada()"
      v-if="Object.keys(lineas).length > 0">
      <v-icon class="mr-1 " color="white">mdi-send-variant-outline</v-icon>Enviar
    </v-btn>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../../components/Menu.vue';
import Scan from '../../components/Shop/scan.vue';

import { AlbaranApiService } from '../../api/AlbaranApiService';
const albaranApiService = new AlbaranApiService();
import { TiendaApiService } from '../../api/TiendaApiService';
const tiendaApiService = new TiendaApiService();

export default Vue.extend({
  name: 'AlbaranDetalle',
  components: {
    Menu,
    Scan,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ean: '',
    audioError: new Audio(require('@/assets/sonidos/error.mp3')),
    audioOk: new Audio(require('@/assets/sonidos/ok.mp3')),
    cargando: false,
    opUid: '',
    opId: '',
    clicode: '',
    lineas: {},
    albaran: {},
    fotos: {},
    buscador: '',
    openedPanel: [],
    isAlbaran: null,
    keyRefresh: 0,
    expandir: false,
    tipoLectura: 0, //0->pistola 1->camara 2-> manual
  }),
  computed: {
    cambiotienda() {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid() {
      return this.$store.getters.getOpuid;
    },
    cambiotiendaCliCode() {
      return this.$store.state.userInfo.clicode;
    },
  },
  watch: {
    cambiotiendaUid(value) {
      this.opUid = value;
      if (this.isAlbaran) {
        this.$router.push({ name: 'Albaranes' })
      } else {
        this.$router.push({ name: 'Bultos' })
      }
    },
    cambiotienda(value) {
      this.opId = value;
    },
    cambiotiendaCliCode(value) {
      this.clicode = value;
    },
    buscador(value) {
      this.filtrarArticulos(value)
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.ean = ''
    document.getElementById("textEan").focus()
  },
  methods: {
    init() {
      this.opId = this.$store.getters.getIdTienda
      this.opUid = this.$store.getters.getOpuid
      this.clicode = this.$store.state.userInfo.clicode
      this.albaran = this.$store.getters.getAlabaranEnCurso
      this.openedPanel = []
      if (this.albaran.barcode_uid) {
        // si tiene barcode es un bulto
        this.isAlbaran = false
      } else {
        this.isAlbaran = true
      }
      this.getDetalle()
    },
    lecturaPistola() {
      this.tipoLectura = 0
      this.ean = ''
      document.getElementById("textEan").focus()
    },
    expandirContraer() {
      if (!this.expandir) {
        let contador = []
        for (let i = 0; i < Object.keys(this.lineas).length; i++) {
          contador.push(i)
        }
        this.openedPanel = contador
      } else {
        this.openedPanel = []
      }
      this.expandir = !this.expandir
    },
    getDetalle() {
      this.cargando = true
      if (this.isAlbaran) {
        albaranApiService.getAlbaranDetalle(this.opUid, this.uid)
          .then((data) => {
            console.log(data)
            if (data.data.length > 0) {
              let result = Object.groupBy(data.data, ({ art_id }) => art_id);
              console.log(result)
              this.lineas = result
              this.getFotos(data.data.map(item => item.art_id).join(','))
              this.completarTallajeArticulos(data.info.articulos)
            } else {
              this.cargando = false
            }
          })
          .catch((error) => {
            this.cargando = false
            this.$root.$emit('mostrarAlerta', 'Error al obtener los articulos', 'error')
            console.log('albaranApiService - getAlbaranDetalle ' + error)
          });
      } else {
        albaranApiService.getBultoDetalle(this.opUid, this.uid)
          .then((data) => {
            console.log(data)
            if (data.data) {
              let result = Object.groupBy(data.data, ({ art_id }) => art_id);
              console.log(result)
              this.lineas = result
              this.getFotos(data.data.map(item => item.art_id).join(','))
              this.completarTallajeArticulos(data.info.articulos)
            } else {
              this.cargando = false
            }

          })
          .catch((error) => {
            this.cargando = false
            this.$root.$emit('mostrarAlerta', 'Error al obtener los articulos', 'error')
            console.log('albaranApiService - getAlbaranDetalle ' + error)
          });
      }
    },
    completarTallajeArticulos(articulos) {
      articulos.forEach(tallaje => {
        let tallajeAux = []
        let tallasConUnidades = this.lineas[tallaje.art_id]
        tallaje.tallas.forEach(talla => {
          let tallaAux = talla
          tallaAux['unidInicial'] = 0
          tallaAux['unid'] = 0
          tallaAux['localizador'] = tallasConUnidades[0].localizador
          tallaAux['modelo'] = tallasConUnidades[0].modelo
          tallaAux['color'] = tallasConUnidades[0].color
          tallaAux['pvp'] = tallasConUnidades[0].pvp
          for (let i = 0; i < tallasConUnidades.length; i++) {
            if (talla.talla_interna === tallasConUnidades[i].talla_interna) {
              tallaAux = tallasConUnidades[i]
              tallaAux['eans'] = talla.eans
              break
            }
          }
          tallajeAux.push(tallaAux)
        });
        this.lineas[tallaje.art_id] = tallajeAux
      });
      this.cargando = false
    },
    getFotos(ids) {
      tiendaApiService.getArticulosFotos(ids)
        .then(data => {
          if (data) {
            this.fotos = data.reduce((acc, curr) => (acc[curr.articulo] = curr, acc), {})
          }
        })
        .catch(error => {
          this.cargando = false
          this.fotos = {}
          console.log(error)
          this.$root.$emit('mostrarAlerta', 'Error obtener imagenes', 'error')
        })
    },
    searchByEanCode() {
      this.ean = this.ean.trim()
      if (this.ean.length > 0) {
        if (this.buscarEanLineas(this.ean)) {
          this.ean = ''
          this.audioOk.pause();
          this.audioOk.currentTime = 0;
          this.audioOk.play()
        } else {
          //si no esta en las lineas y tampoco al buscarlo damos error
          this.cargando = true
          tiendaApiService.getArticuloByEan(this.ean)
            .then(data => {
              // console.log(data)
              if (data) {
                let tallas = []
                let idArtic = data.id
                data.tallas.forEach(element => {
                  if (element.eans && element.eans.length > 0) {
                    let unidades = 0
                    let eanAux = []
                    if (element.eans.some(item => item.ean.toString().trim() == this.ean.toString().trim())) {
                      unidades = 1
                      eanAux = [this.ean.toString().trim()]
                    } else {
                      eanAux = element.eans.map(o => o.ean)
                    }
                    let precio = 0
                    // TODO: optimizar la llamada para solo coger el precio de la op y no cargar todo el stock
                    // INFO: No hace falta añadir precio
                    // let objStock = element.stocks.filter(item => item.op.msopid.toString().trim() == this.opId.toString().trim())
                    // if (objStock.length > 0) {
                    //   precio = objStock[0].precio.precio
                    // }
                    tallas.push({
                      art_id: idArtic,
                      color: data.color,
                      descr: data.descripcionTpv,
                      eans: eanAux,
                      localizador: data.localizador,
                      modelo: data.modelo,
                      talla: element.talla,
                      talla_interna: element.tallaInterna,
                      unidInicial: 0,
                      unid: unidades,
                      pvp: precio,
                      added: true
                    })
                  }
                });
                this.fotos = Object.assign({ [idArtic]: data.fotos[0] }, this.fotos)
                this.lineas = Object.assign({ [idArtic]: tallas }, this.lineas)
                // console.log('lineas', this.lineas)
                this.$root.$emit('mostrarAlerta', 'Articulo añadido', 'green')
                this.cargando = false
                this.openedPanel = [Object.keys(this.lineas).findIndex((element) => element === idArtic.toString())]
                this.ean = ''
                this.audioOk.pause();
                this.audioOk.currentTime = 0;
                this.audioOk.play()
                this.$forceUpdate()
                this.keyRefresh += 1
                this.$nextTick(() => {
                  document.getElementById(idArtic).scrollIntoView()
                })
              } else {
                this.cargando = false
                this.audioError.pause();
                this.audioError.currentTime = 0;
                this.audioError.play()
                this.ean = ''
                this.$root.$emit('mostrarAlerta', 'Ean no encotrado', 'error')
              }
            })
            .catch(error => {
              this.cargando = false
              this.audioError.pause();
              this.audioError.currentTime = 0;
              this.audioError.play()
              this.ean = ''
              console.log(error)
              this.$root.$emit('mostrarAlerta', 'Error al buscar el articulo', 'error')
            })
        }

      } else {
        this.audioError.pause();
        this.audioError.currentTime = 0;
        this.audioError.play()
        this.ean = ''
        this.$root.$emit('mostrarAlerta', 'Ean vacio', 'error')
      }
    },
    buscarEanLineas(ean) {
      let contador = 0
      for (var artic in this.lineas) {
        for (let x = 0; x < this.lineas[artic].length; x++) {
          for (let y = 0; y < this.lineas[artic][x].eans.length; y++) {
            if (this.lineas[artic][x].eans[y] === ean) {
              document.getElementById(artic).scrollIntoView();
              this.lineas[artic][x].eans = [ean]
              this.openedPanel = [contador]
              if (this.lineas[artic][x].unidInicial == undefined) {
                this.lineas[artic][x].unidInicial = this.lineas[artic][x].unid
                this.lineas[artic][x].unid = 1
              } else {
                this.lineas[artic][x].unid++
              }
              return true
            }
          }
        }
        contador++
      }
      return false
    },
    borrarEntrada() {
      this.$root.$confirm.open('Descartar', '¿Deseas descartar la entrada y empezar de cero?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          for (var artic in this.lineas) {
            for (let x = 0; x < this.lineas[artic].length; x++) {
              this.lineas[artic][x].unid = 0
            }
          }
        }
      })
    },
    eliminarArticulo(idArtic) {
      this.$root.$confirm.open('Eliminar', '¿Deseas eliminar el articulo?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          delete this.lineas[idArtic]
          this.$forceUpdate()
        }
      })

    },
    filtrarArticulos() {
      if (this.buscador && this.buscador.length > 0) {
        let lineasFiltradas = []
        lineasFiltradas = Object.values(this.lineas).filter(item => {
          return (item[0].localizador.toString().indexOf(this.buscador) >= 0 ||
            item[0].modelo.toUpperCase().indexOf(this.buscador.toUpperCase()) >= 0
          )
        })
        console.log(lineasFiltradas)
        this.$refs.articulos.forEach(element => {
          if (lineasFiltradas.some(item => item[0].art_id == element.$el.id)) {
            element.$el.hidden = false
          } else {
            element.$el.hidden = true
          }
        });
      } else {
        this.$refs.articulos.forEach(element => {
          element.$el.hidden = false
        });
      }
    },
    // filtrarArticulos() {
    //   if (this.buscador && this.buscador.length > 0) {
    //     let lineasFiltradas = []
    //     lineasFiltradas = Object.values(this.lineas).filter(item =>{
    //       return (item[0].localizador.toString().indexOf(this.buscador) === 0 || 
    //               item[0].modelo.toUpperCase().indexOf(this.buscador.toUpperCase()) === 0
    //             )
    //     })
    //     console.log(lineasFiltradas)
    //     let encontrado = false
    //     this.$refs.articulos.forEach(element => {
    //       if (lineasFiltradas.some(item => item[0].art_id == element.$el.id )) {
    //         element.$el.hidden = false
    //         encontrado = true
    //       } else {
    //         element.$el.hidden = true
    //       }
    //     });
    //     if (!encontrado && this.buscador.length >= 13) {
    //       // puede ser un ean miramos que se un ean de los que ya estan sino hay que añadir el articulo
    //       this.$refs.articulos.forEach(element => {
    //         element.$el.hidden = false
    //       })
    //       if (!this.buscarEanLineas(this.buscador)) {
    //         this.ean = this.buscador
    //         this.searchByEanCode() 
    //       } else {
    //         this.ean = ''
    //         this.audioOk.pause();
    //         this.audioOk.currentTime = 0;
    //         this.audioOk.play()
    //         this.buscador = ''
    //       }
    //     }
    //   } else {
    //     this.$refs.articulos.forEach(element => {
    //         element.$el.hidden = false
    //     });
    //   }
    // },
    clearBuscador() {
      this.$refs.articulos.forEach(element => {
        element.$el.hidden = false
      });
    },
    enviarEntrada() {
      this.$root.$confirm.open('Eliminar', '¿Deseas registrar la entrada?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.cargando = true
          let entrada = []
          entrada.push(this.opUid)
          entrada.push(this.opId)
          entrada.push(this.albaran.origen_id)
          entrada.push(this.clicode)
          entrada.push(this.albaran.origen_cod)
          entrada.push(this.$store.state.userInfo.codVendedor)
          entrada.push(this.albaran.albaran_id)
          if (!this.isAlbaran) {
            entrada.push(this.albaran.barcode)
          } else {
            entrada.push("")
          }
          for (var artic in this.lineas) {
            for (let x = 0; x < this.lineas[artic].length; x++) {
              if (this.lineas[artic][x].unid > 0) {
                entrada.push({
                  tallaInterna: this.lineas[artic][x].talla_interna + 1,
                  tallaExterna: this.lineas[artic][x].talla,
                  unidades: this.lineas[artic][x].unid,
                  ean: this.lineas[artic][x].eans[0],
                  idArtic: artic,
                  precio: this.lineas[artic][x].pvp
                })
              }
            }
          }
          albaranApiService.enviarEntrada(entrada)
            .then(() => {
              this.cargando = false
              this.$root.$emit('mostrarAlerta', 'Entrada registrada con éxito', 'green')
              // if (this.isAlbaran) {
              //   this.$router.push({ name: 'Albaranes' });
              // } else {
              //   this.$router.push({ name: 'Bultos' });
              // }
              this.$router.push({ name: 'Entradas' });
            })
            .catch(error => {
              this.cargando = false
              this.$root.$emit('mostrarAlerta', 'Error al enviar la entrada', 'error')
              console.log('alabaranApiService - enviarEntrada ' + error)
            })
        }
      })
    }
  },
});
</script>
