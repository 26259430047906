<template>
  <v-list class="px-3 ma-0 pr-3 advange-list overflow-x-hidden">
    <h3 class="text-uppercase primary--text text-center py-3">Total: {{total}}</h3>
      <div class="d-flex align-center pr-4 pl-14 pt-15 mt-10">
        <v-text-field class="buscar  pt-0" hide-details clearable text ref="buscador" label="Buscar Ean"
          v-model.trim="buscador" @click:clear="clearBuscador()"></v-text-field>
        <v-icon class="icon search" icon large @click="buscarEan()"> </v-icon>
      </div>
      <div class="ma-0 d-flex" v-for="(articulo, index) in articulos" :key="index">
        <v-list-item-avatar size="80" class="ma-0"  v-if="articulo.foto !== ''">
          <v-img :src="articulo.foto" class="pr-4 mr-2" max-height="80" max-width="90" contain> </v-img>
        </v-list-item-avatar> 
        <v-list-item>
          <v-list-item-content class="pb-0">
            <p class="text-subtitle-1 mb-0  primary--text pa-0 text-uppercase text-truncate text-overline">{{ articulo.ean }}</p>
            <v-list-item-title class="primary--text text-upper text-uppercase text-subtitle-1">{{ articulo.articulo.modelo}}</v-list-item-title>
            <v-list-item-subtitle class="accent--text pa-0 subtitle-1">{{ articulo.articulo.color }}</v-list-item-subtitle>
            <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate align-center"> 
              <span v-if="articulo.talla !== ''">Talla {{ articulo.talla }}</span>
              <!-- <span class="mx-2">Stock {{ articulo.stock }}</span> -->
              <span class="text-h6 ml-auto d-flex align-center px-3">
                <!-- TODO: sumar restar lineas -->
                <!-- <v-btn color="secondary" icon outlined @click="(articulo.unidades > 0) ? restar(articulo.ean) : ''"><v-icon>mdi-minus</v-icon></v-btn> -->
                <v-text-field disabled class="px-3 py-0" :value="articulo.unidades "> </v-text-field>
                <v-btn text right top icon primary v-if="articulos.length > 1">
                  <v-icon @click="editarCantidad(index, articulo.ean, articulo.unidades)">mdi-pen</v-icon>
                </v-btn>
                <!-- <v-btn icon color="secondary" outlined @click="sumar(articulo.ean)"><v-icon>mdi-plus</v-icon></v-btn>  -->
              </span>
            </p>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-btn text absolute right top icon primary class="pa-0">
          <v-icon @click="eliminar(index)">mdi-delete</v-icon>
        </v-btn> -->
      </div>
      <v-dialog persistent v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">Editar cantidad</v-toolbar-title>
          </v-toolbar>
          <v-card-title class="subheading">{{ ean }}
            <v-btn text right top icon primary class="pa-0">
              <v-icon @click="eliminar(ean,index)">mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <div class="d-flex align-center px-6">
            <v-btn color="secondary" icon outlined>
              <v-icon @click="(cantidad>1) ? cantidad-- : ''">
                mdi-minus
              </v-icon>
            </v-btn>
            <v-text-field class="text-h5 px-4" v-model="cantidad" label="Unidades" type="number"></v-text-field>
            <v-btn color="secondary" icon outlined>
              <v-icon @click="cantidad++">
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog=false">Cancelar</v-btn>
            <v-btn color="secondary" @click="guardarCantidad()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
 </v-list>
</template>

<script lang="ts">
import Vue from 'vue';
// import { TiendaApiService } from '../api/TiendaApiService';
// const tiendaApiService = new TiendaApiService();
import { InventarioApiService } from '../api/InventarioApiService';
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'AdvangeList',
  components: {

  },
  props: {
    total: {
      type: Number,
      required: false,
    },
  },
  data() {
   return {
      e6: 0,
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
      opId: '',
      opUid: '',
      ean: '',
      foto: '',
      tallas: [],
      dialog: false,
      tipo: 'completo',
      sheet: true,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      },
      buscador: '',
      index:'',
      cantidad: '',
      usuario: this.$store.state.userInfo.user.substring(0, 50).toLowerCase(),
      articulos: this.$store.state.inventarioArticulosAvanzado

    };
  },
  computed: {
    cambiotienda () {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid () {
      return this.$store.getters.getOpuid;
    },
    cambioArticulos () {
      return this.$store.state.inventarioArticulosAvanzado
    },
    isOfflineDb() {
      return this.$store.state.isOfflineDb
    },
  },
  watch: {
    cambiotienda (value) {
      this.opId = value
      this.init()
    },
    cambiotiendaUid (value) {
      this.opUid = value
      this.init()
    },
    cambioArticulos (value) {
      this.articulos = value
    },
  },
  mounted() {
    this.opId = this.$store.getters.getIdTienda
    this.opUid = this.$store.getters.getOpuid
    this.init()
  },
  methods: {
    init() {
      this.ean = '' 
      // console.log(this.$store.state.inventarioArticulosAvanzado)
    },
    buscarEan() {
      this.articulos = [this.articulos.find((articulo) => articulo.ean === this.buscador)]
    },
    clearBuscador() {
      this.articulos = this.$store.state.inventarioArticulosAvanzado
      this.buscador = ''
    },
    // sumar(ean) {
    //   this.$store.commit('addUdInventarioArticulosAvanzado', ean)
    // },
    // restar(ean) {
    //   this.$store.commit('removeUdInventarioArticulosAvanzado', ean)
    // },
    editarCantidad(index, ean, cantidad) {
      this.dialog = true
      this.index = index
      this.ean = ean
      this.cantidad = cantidad
    },
    // searchByEanCode() {
    //   // alert(ean)
    //   if (this.ean.length > 0) {
    //     let encontrado
    //     for (var x=0; x <  this.articulos.length; x++) {
    //       if (this.articulos[x].ean === this.ean) {
    //         this.$store.commit('addUdInventarioArticulosAvanzado', this.ean)
    //         encontrado = true
    //         this.init()
    //       }
    //     }
    //     if (!encontrado) {
    //       this.getProductBy(this.ean)
    //     }
    //   } else {
    //     this.snackbar = true
    //     this.snackColor = 'red darken-2'
    //     this.snackText = 'No se ha podido escanear el código'
    //   }
    // },
    // getProductBy(ean) {
    //   // var filtro = {}
    //   // Object.assign(filtro, { ean: ean })
    //   tiendaApiService
    //     .getArticuloByCod(ean)
    //     .then((data) => {
    //       let foto, stock = 0, unidades = 1, talla, stockObj
    //       if (data.articulo !== null) {
    //         if (data.articulo.fotos.length > 0) {
    //           foto = data.articulo.fotos[0].url;
    //         } else {
    //           foto= ''
    //         }
    //         outer_loop:
    //         for (var x=0; x < data.articulo.tallas.length; x++) {
    //           for (var y=0; y < data.articulo.tallas[x].eans.length; y++) {
    //             if (data.articulo.tallas[x].eans[y].ean === ean) {
    //               talla = data.articulo.tallas[x].talla
    //               if (data.articulo.tallas[x].stocks.length > 0) {
    //                 stockObj = data.articulo.tallas[x].stocks.find(element => element.id.id_op === this.opId)
    //                 if (stockObj) {
    //                   stock = stockObj.unidades
    //                 }
    //               }
    //               break outer_loop
    //             }
    //           } 
    //         }
    //         this.$store.commit('addToInventarioArticulosAvanzado',{
    //                             'articulo': data.articulo,
    //                             'talla': talla,
    //                             'stock': stock,
    //                             'unidades': unidades,
    //                             'foto': foto,
    //                             'ean': ean,
    //                             })
    //         this.$nextTick(() => {
    //            window.scrollTo(0,document.body.scrollHeight);
    //         })   
    //         this.init()
    //       } else {
    //         this.snackbar = true
    //         this.snackColor = 'red darken-2'
    //         this.snackText = 'No se ha encontrado el articulo'
    //         this.init()
    //       }
    //     })
    //     .catch((error) => {
    //       this.init()
    //       this.snackbar = true
    //       this.snackColor = 'red darken-2'
    //       this.snackText = 'Ha ocurrido un error'
    //       console.log('tiendaApiService - findArticulos ' + error)
    //     });
    // },
    // guardarInventario() {           
    //   this.$root.$confirm.open('Enviar', '¿Deseas enviar el inventario?', { color: 'primary' }).then((confirm) => {
    //     if (confirm) {
    //       let inventario = []
    //       inventario.push(this.tipo)
    //       inventario.push(this.$store.state.inventarioArticulosAvanzado.map((element) => {
    //       return element.ean
    //       }) )
    //       console.log(inventario)
    //       tiendaApiService.enviarInventario(inventario)
    //       .then(() => {
    //       this.snackbar = true
    //       this.snackColor = 'green'
    //       this.snackText = 'Inventario registrado con éxito'
    //       })
    //       .catch(error => {
    //       this.snackbar = true
    //       this.snackColor = 'red darken-2'
    //       this.snackText = 'Error al enviar el inventarío'
    //       console.log('tiendaApiService - enviarInventario ' + error)
    //       })
    //       this.$store.state.inventarioArticulosAvanzado = []
    //     }
    //   })
    // },
    eliminar(ean,index) {
      this.$root.$confirm.open('Borrar', '¿Deseas borrar el articulo?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          if (this.isOfflineDb) {
            this.$emit('updateEanCompletoIndexDb', ean, index, 0)
          } else {
            // this.total -= this.cantidad
            let linea = {
              msiEan: this.ean,
              msiCantidad: 0,
              msiUsuario: this.usuario,
              msiOp: this.opUid
            }
            inventarioApiService.updateEanCantidad(linea)
              .then((data) =>{
                if (data) {
                  this.snackbar = true
                  this.snackColor = 'green'
                  this.snackText = 'Articulo editado correctamente'
                  this.$store.commit('removeFromInventarioArticulosAvanzado',index)
                  this.dialog = false
                } else {
                  this.snackbar = true
                  this.snackColor = 'red darken-2'
                  this.snackText = 'Ha ocurrido un error'
                }
                this.dialog = false
              })
              .catch(error => {
                console.log(error)
                this.dialog = false
                this.snackbar = true
                this.snackColor = 'red darken-2'
                this.snackText = 'Ha ocurrido un error'
              })
          }
          this.dialog = false
        }
      })
    },
    guardarCantidad() {
      if (!this.isOfflineDb) {
        let linea = {
          msiEan: this.ean,
          msiCantidad: this.cantidad,
          msiUsuario: this.usuario,
          msiOp: this.opUid
        }
        inventarioApiService.updateEanCantidad(linea)
          .then((data) =>{
            if (data) {
              this.snackbar = true
              this.snackColor = 'green'
              this.snackText = 'Articulo editado correctamente'
              this.$store.commit('editFromInventarioArticulosAvanzado',linea)
              this.dialog = false
            } else {
              this.snackbar = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Ha ocurrido un error'
            }
            this.dialog = false
          })
          .catch(error => {
            console.log(error)
            this.dialog = false
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'Ha ocurrido un error'
          })
      } else {
        this.$emit('updateEanCompletoIndexDb', this.ean, null, this.cantidad)
        this.dialog = false
      }  
    }
  },
});
</script>
