<template>
  <v-app class="entrada-salida">
    <header class="pl-13 v-app-bar" style="background: #fff;">
      <div class="v-toolbar__content" style="height: 56px;">
        <div elevation="0" class="pl-0 header-icon">
          <v-icon class="icon caja"></v-icon>
        </div>
      </div>
    </header>
    <Menu />
     
    <v-main class="background">
      <!--
      <v-row class="col-12 pa-0 ma-0 scan" >
        <v-col class="primary col-12 pa-0">
          <Scan @search-by-ean-code="searchByEanCode"/>
        </v-col>
      </v-row> -->
      <!-- <v-row class="col-12 pa-0 ma-0 scan" >
        <v-col class="col-12 p-2">
          <v-text-field
            label="Localizador / Código"
            text
            clearable
            ref="textEan"
            v-model="ean" 
            @keyup.enter="searchByEanCode(ean)"
            ></v-text-field>
        </v-col>
      </v-row>
      -->

      <div class="mx-auto my-auto">
          <router-link class="mx-auto d-flex" :to="{ name: 'Albaranes'}"> 
            <v-btn large class="primary "
            >
              <v-icon class="mr-3 ">mdi-file-document-outline</v-icon>
              Entrada por ODT / Albaran
            </v-btn>
          </router-link>

          <router-link class="d-flex  mt-6" :to="{ name: 'Bultos'}"> 
            <v-btn large
            class="primary col-12"
            >
            <v-icon class="mr-3 ">mdi-archive-outline</v-icon>
              Entrada por bulto / SSCC
            </v-btn>
          </router-link>
     </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../../components/Menu.vue';

export default Vue.extend({
  name: 'Profile',
  components: {
    Menu
  },
  data: () => ({
    codOdt: '',
    files: [
      { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC1', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-close-circle', color: 'secondary', subtitle: '3/03/2014', title: 'SSCC2', number: '0028434690000', bold: '255732' },
    ],
    folders: [
      { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC3', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC4', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC5', number: '0028434690000', bold: '255732' },
    ],
  }),
  created() {
    this.$root.$emit('verificarCertificado', location.href)
  },
  mounted() {
    
  },
  methods: {
  },
});
</script>
