<template>
  <v-main class="background d-flex">  
    <v-app-bar  elevation="0" class="pl-16" color="transparent">
          
    </v-app-bar>
      <Menu />
    <v-card class="d-flex justify-center flex-column pt-10"
      color="white"
      elevation="0">
        <v-card-title class="justify-center primary--text py-2">{{ fechaActualEsp }}</v-card-title>
        <v-card-title class="text-h2 primary--text font-weight-bold py-0 text-center justify-center">{{ horaActual }}h</v-card-title>
    </v-card>
    <v-list color="transparent" class="pb-0">
      <v-card elevation="0">
        <h3 class="text-uppercase primary--text text-center py-3">
          <!-- <v-icon class="icon lista ">
          </v-icon>
          Historial de fichaje -->
        </h3>
        <!-- <v-divider></v-divider> -->
      </v-card>
      <!-- <v-card class="scroll" color="transparent" elevation="0">
        
      </v-card> -->
    </v-list> 
  </v-main>   
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'

  const moment = require("moment") 

  export default Vue.extend({
    name: 'Inicio',
    components: {
      Menu,
    },
     data: () => ({
      ahora: moment()
    }),
    computed: {
      horaActual: function() {
        return moment(this.ahora).format("H:mm")
      },
      fechaActual: function() {
        return moment(this.ahora).format("YYYY-MM-DD");
      },
      fechaActualEsp: function() {
        return moment(this.ahora).format("DD/MM/YYYY");
      },
    },
    watch: {
     
    },
    created () {
      
    },
    methods: {
    }
  })
</script>


